<template>
  <span>
    <b-icon-dash-circle-fill scale="1.4" @click="$bvModal.show(modalId)"/>

    <b-modal ref="modal" :id="modalId" title="Delete server" centered>
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            Really delete server <strong>{{ server.url }}</strong>?
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="danger" @click="deleteServer">
          <b-spinner small v-show="processing"></b-spinner>
          <span v-show="!processing">Delete</span>
        </b-button>
      </template>
    </b-modal>
  </span>
</template>


<script>

import {API} from "aws-amplify";
import {refreshServers, store} from "@/main";


export default {
  name: "DeleteServerButton",
  props: {
    server: Object,
  },
  data: function() {
    return {
      processing: false,
      err: ' ',
    }
  },
  computed: {
    modalId: function() {
      return 'modal-delete-server-' + this.server.id
    },
  },
  methods: {
    deleteServer() {
      this.processing = true;
      this.err = ' ';
      API.post(store.state.api, "/del", {
        body: JSON.stringify({
          q: 1,
          serverId: this.server.id,
        }),
      })
          .then(() => {
            this.processing = false;
            this.$refs['modal'].hide()
            refreshServers()
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error deleting server'
          });
    }
  },
}
</script>



<style scoped>
.error {
  display: block;
  color: red;
  margin-top: 10px;
  text-align: center;
}
.b-icon {
  cursor: pointer;
  transition: color .2s ease;
  color: #aaa;
}
.b-icon:hover {
  color: #dc3545;
}
</style>