<template>
  <span>
    <b-button variant="outline-primary" @click="downloadCSV"><b-icon-download  style="margin-right: 5px"/>Download Usage CSV</b-button>
    <a id="blob-link-download-csv"></a>
  </span>
</template>

<script>
export default {
  name: "DownloadKeycloakApiKeyUsageCsvButton",
  props: {
    apiKeys: Array,
    client: Object,
    keycloak: Object,
  },
  data() {
    return {
      processing: false,
      err: ' ',
    }
  },
  methods: {
    downloadCSV() {
      const a = document.getElementById('blob-link-download-csv');

      let csvLines = ['API Key; Creation; Revocation\n'];
      this.apiKeys.forEach(apiKey => {
        csvLines.push(apiKey.apiKey + '; ' + apiKey.creationTimestamp + '; ' + (apiKey.revocationTimestamp !== undefined ? apiKey.revocationTimestamp : '') + '\n');
      });
      const blob = new Blob(csvLines, {type: "text/csv"});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.client.name + '_' + this.keycloak.name + '_keycloak_api_key_usage.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    },
  }
}
</script>

<style scoped>
#blob-link-download-csv {
  display: none;
}
ul {
  margin: 20px 0;
}
li {
  font-family: monospace;
  font-size: 75%;
}
</style>