<template>
  <div id="app" v-if="user !== null">
    <div id="left-menu">
        <ul>
          <li class="activatable" @click="routerPush('/servers')" :class="routerPath === '/servers' ? 'active': ''">Servers</li>
          <li class="activatable" @click="routerPush('/')" :class="routerPath === '/' ? 'active': ''">Clients</li>
          <ul>
            <li v-for="client in sortedClients"
                :key="client.id"
                @click="() => {routerPush('/client/' + client.id)}"
                :class="'activatable' + (routerPath  === ('/client/' + client.id) ? ' active':'')">{{ client.name }}
            </li>
          </ul>
        </ul>
    </div>

    <router-view v-if="user !== null"></router-view>
  </div>
  <div v-else>
    <div style="text-align: center;margin-top: 50px">Please sign in</div>
  </div>
</template>

<script>
import {refreshStore, store} from "@/main.js";

const collator = new Intl.Collator('fr', {sensitivity: 'base'})

export default {
  name: 'App',
  data() {
    return {
      storeState: store.state,
    }
  },
  methods: {
    routerPush: function (path) {
      this.$router.push(path)
    },
  },
  computed: {
    prod() {
      return store.state.prod;
    },
    active() {
      return store.state.active;
    },
    user() {
      return store.state.user;
    },
    sortedClients() {
      return store.state.clients === null?[]:Object.values(store.state.clients).sort((clientA, clientB) => {return collator.compare(clientA.name, clientB.name)})
    },
    routerPath() {
      return this.$route.path
    },
  },
  watch: {
    prod: function() {
      refreshStore()
      this.routerPush('/')
    },
  },
  mounted: function() {
    refreshStore()
  }
}
</script>

<style>
#app {
  padding-left: 200px;
  position: relative;
}
#left-menu {
  position: fixed;
  top: 66px;
  left: 0;
  width: 200px;
  height: calc(100vh - 66px);
  padding-bottom: 50px;
  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
}
#left-menu::-webkit-scrollbar {
  display: none;
}
#left-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#left-menu > ul > li {
  position: relative;
  display: block;
  font-size: 150%;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#left-menu > ul > li.active {
  padding-left: 10px;
}
#left-menu .activatable:hover {
  background-color: #f8f9fa;
}
#left-menu .activatable.active {
  border-left: 5px solid #007bff;
}
#left-menu > ul > ul > li {
  font-size: 100%;
  padding: 2px 5px 2px 30px;
  cursor: pointer;
}
#left-menu > ul > ul > li.active {
  padding-left: 25px;
}
</style>
