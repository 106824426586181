<template>
    <b-navbar sticky variant="light">
      <b-navbar-brand><img alt="Olvid logo" src="../assets/olvid.png"></b-navbar-brand>
      <sync-button v-if="storeState.user !== null"/>
      <b-navbar-nav class="ml-auto" v-if="storeState.user !== null">
        <b-nav-form><toggle-button color="#cc4444" :width=60 :sync="true" :value="storeState.prod" :labels="{checked: 'Prod', unchecked: 'Dev'}" @change="toggleProd"/></b-nav-form>
        <b-nav-item>Connected as <strong>{{ storeState.user.attributes.email }}</strong></b-nav-item>
        <b-nav-form><b-button id="signout" variant="warning" v-on:click="signout">Sign out</b-button></b-nav-form>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto" v-else>
        <b-button id="signin" variant="info" v-on:click="signin">Sign In</b-button>
      </b-navbar-nav>

    </b-navbar>
</template>

<script>
import { Auth } from 'aws-amplify';
import { store } from "@/main";
import SyncButton from "@/components/buttons/SyncButton.vue";

export default {
  name: "AuthenticationHeader",
  components: {SyncButton},
  data() {
    return {
      storeState: store.state,
    }
  },
  methods: {
    log(e) {console.log(e)},
    signin() {Auth.federatedSignIn();},
    signout() {Auth.signOut();},
    getUser() {Auth.currentUserInfo().then((usr) => store.setUser(usr)).catch(() => store.setUser(null))},
    toggleProd(input) {store.setProd(input.value)},
  },
  mounted: function() {
    this.getUser();
  },
}
</script>

<style scoped>

img {
  height: 40px;
  display: block;
}
</style>