<template>
  <span class="block" @click="toggle">
    <b-icon-star-fill class="icon" variant="warning" v-if="client.starredTab === tabId"/>
    <b-icon-star class="icon" v-else/>
    <span class="mask" v-if="processing">
      <b-spinner class="centered-spinner" variant="primary"/>
    </span>
  </span>
</template>

<script>
import {API} from "aws-amplify";
import {store} from "@/main";

export default {
  name: "StarTab",
  props: {
    client: Object,
    tabId: Number,
  },
  data: function() {
    return {
      editing: false,
      editedText: null,
      processing: false,
    }
  },
  methods: {
    toggle() {
      this.processing = true
      let targetId = this.tabId;
      if (this.client.starredTab === this.tabId) {
        targetId = null
      }

      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 55,
          clientId: this.client.id,
          starredTab: targetId,
        })
      })
          .then(response => {
            if (response === true) {
              this.processing = false
              store.state.clients[this.client.id].starredTab = targetId
            } else {
              throw response
            }
          })
          .catch(() => {
            this.processing = false
          })
    },
  }
}
</script>

<style scoped>
.block {
  display: inline-block;
  position: relative
}
.icon {
  font-size: 60%;
  margin-bottom: .2em;
}
.mask {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255,255,255,0.75);
}
.centered-spinner {
  font-size: 1rem;
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}
</style>