<template>
  <span>
    <b-button variant="danger" @click="$bvModal.show(modalId)"><b-icon-dash-circle-fill style="margin-right: 5px; color: white;"/> Delete keycloak</b-button>

    <b-modal ref="modal" :id="modalId" title="Delete keycloak" centered>
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            Really delete keycloak <strong>{{ keycloak.name }}</strong>?
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="danger" @click="deleteKeycloak">
          <b-spinner small v-show="processing"></b-spinner>
          <span v-show="!processing">Delete</span>
        </b-button>
      </template>
    </b-modal>
  </span>
</template>


<script>

import {API} from "aws-amplify";
import {refreshClients, store} from "@/main";
import modal from "bootstrap/js/src/modal";


export default {
  name: "DeleteKeycloakButton",
  props: {
    keycloak: Object,
  },
  data: function() {
    return {
      processing: false,
      err: ' ',
    }
  },
  computed: {
    modal() {
      return modal
    },
    modalId: function() {
      return 'modal-delete-keycloak-' + this.keycloak.id
    },
  },
  methods: {
    deleteKeycloak() {
      this.processing = true;
      this.err = ' ';
      API.post(store.state.api, "/del", {
        body: JSON.stringify({
          q: 4,
          keycloakId: this.keycloak.id,
        }),
      })
          .then(() => {
            this.processing = false;
            this.$refs['modal'].hide()
            refreshClients()
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error deleting keycloak'
          });
    }
  },
}
</script>



<style scoped>
.error {
  display: block;
  color: red;
  margin-top: 10px;
  text-align: center;
}
.b-icon {
  cursor: pointer;
  transition: color .2s ease;
  color: #aaa;
}
.b-icon:hover {
  color: #dc3545;
}
</style>