<template>
  <span>
    <b-button variant="success" v-b-modal.modal-add-server><b-icon-plus-square-fill style="margin-right: 5px"/> Add server</b-button>

    <b-modal ref="modal" id="modal-add-server" title="Add server" centered>
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="3">
            <label for="url-input">Url:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input id="url-input" type="text" placeholder="Server URL" v-model="urlInput"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="createServer" :disabled="!urlInput">
          <b-spinner small v-if="processing"></b-spinner>
          <span v-show="!processing">Create</span>
        </b-button>
      </template>
    </b-modal>


  </span>
</template>


<script>

import {API} from "aws-amplify";
import {refreshServers, store} from "@/main";

export default {
  name: "AddServerButton",
  data: function() {
    return {
      urlInput: '',
      processing: false,
      err: ' ',
    }
  },
  methods: {
    createServer() {
      this.processing = true;
      this.err = ' ';
      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 1,
          url: this.urlInput
        }),
      })
          .then(() => {
            this.processing = false;
            this.$refs['modal'].hide()
            refreshServers()
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error creating server'
          });
    }
  }
}
</script>



<style scoped>
.error {
  display: block;
  color: red;
  margin-top: 10px;
  text-align: center;
}
</style>