<template>
  <span>
    <b-button variant="outline-primary" v-b-modal.modal-apikey-csv><b-icon-download  style="margin-right: 5px"/>Download CSV</b-button>


<!--    @click="downloadCSV"-->
    <b-modal ref="modal" id="modal-apikey-csv" :title="'Download API key CSV for ' + client.name" centered>
      <b-container fluid>
        <b-form-group label="Additional columns to include:">
          <b-form-checkbox-group
              id="column-checkboxes-group"
              v-model="selectedColumns"
              :options="columnOptions"
              name="column-checkboxes"/>
        </b-form-group>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="() => {downloadCSV(); cancel()}">
          Download CSV
        </b-button>
      </template>
    </b-modal>
    <a id="blob-link-download-csv"></a>
  </span>
</template>

<script>

import {generateConfigurationLink} from "@/main";

export default {
  name: "DownloadApiKeysCsvButton",
  props: {
    apiKeys: Array,
    client: Object,
  },
  data() {
    return {
      selectedColumns: [],
      columnOptions: [{ text: 'Assigned', value: 'assigned' }, { text: 'Revoked', value: 'revoked' }],
    }
  },
  methods: {
    downloadCSV() {
      const a = document.getElementById('blob-link-download-csv');
      const assigned = this.selectedColumns.includes('assigned')
      const revoked = this.selectedColumns.includes('revoked')
      let csvLines = ['API Key;Configuration Link' + (assigned ? ';Assigned' : '') + (revoked ? ';Revoked' : '') + '\n'];
      this.apiKeys.forEach(apiKey => {
        csvLines.push(apiKey.apiKey + ';'
            + generateConfigurationLink(apiKey) +
            (assigned ? (apiKey.assigned ? ';yes' : ';no') : '') +
            (revoked ? (apiKey.active ? ';no' : ';yes') : '') +
            '\n');
      });
      const blob = new Blob(csvLines, {type: "text/csv"});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.client.name + '_api_keys.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    },
  }
}
</script>

<style scoped>
#blob-link-download-csv {
  display: none;
}
ul {
  margin: 20px 0;
}
li {
  font-family: monospace;
  font-size: 75%;
}
</style>