import { render, staticRenderFns } from "./AddServerButton.vue?vue&type=template&id=051cbd33&scoped=true"
import script from "./AddServerButton.vue?vue&type=script&lang=js"
export * from "./AddServerButton.vue?vue&type=script&lang=js"
import style0 from "./AddServerButton.vue?vue&type=style&index=0&id=051cbd33&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051cbd33",
  null
  
)

export default component.exports