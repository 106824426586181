import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
Vue.config.runtimeCompiler = true

import {Amplify, Auth, API} from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AuthenticationHeader from "./components/AuthenticationHeader";

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import {base64encode} from "./base64";
import VueRouter from "vue-router";
import ClientList from "./components/ClientList.vue";
import ServerList from "./components/ServerList.vue";
import ApiKeySearch from "./components/ApiKeySearch.vue";
import ClientDetails from "./components/ClientDetails.vue";
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)

const admin_app_url = window.location.protocol + '//' + window.location.host;

const config = {
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-central-1:870c527d-ad67-41f0-ad2f-0f336326f806',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_tqCGyDTRx',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6rsqbl1sj5fahmdp9biro94f5e',

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: true,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: "strict" | "lax",
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // // OPTIONAL - customized storage object
    // storage: MyStorage,

    // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'server-admin-auth.olvid.io',
      scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin', 'https://nrpntighgb.execute-api.eu-west-3.amazonaws.com/access'],
      redirectSignIn: admin_app_url,
      redirectSignOut: admin_app_url,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  API: {
    endpoints: [
      {
        name: "prod",
        endpoint: "https://nrpntighgb.execute-api.eu-west-3.amazonaws.com/prod",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          }
        }
      },
      {
        name: "dev",
        endpoint: "https://4nf86p72x5.execute-api.eu-west-3.amazonaws.com/dev",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          }
        }
      }
    ]
  }
}

Amplify.configure(config)
API.configure(config)

const routes = [
  {
    path: '/',
    component: ClientList,
  },
  {
    path: '/client/:clientId',
    component: ClientDetails,
    props: true,
  },
  {
    path: '/servers',
    component: ServerList,
  },
  {
    path: '/search/:filter',
    component: ApiKeySearch,
    props: true,
  },
]

const router = new VueRouter({
  mode: "history",
  routes: routes,
})

export const store = {
  state: {
    prod: true,
    api: "prod",
    user: null,
    users: null,
    servers: null,
    clients: null,
  },
  setUser(user) {
    this.state.user = user
  },
  setProd(bool) {
    this.state.prod = bool
    this.state.api = bool ? "prod" : "dev"
  },
  setUsers(users) {
    this.state.users = users
  },
  setServers(servers) {
    this.state.servers = servers
  },
  setClients(clients) {
    this.state.clients = clients
  },
}

export const refreshStore = function() {
  store.state.users = null;
  refreshUsers();
  store.state.servers = null;
  refreshServers();
  store.state.clients = null;
  refreshClients();
}
export const refreshUsers = function () {
  API.post(store.state.api, "/get", {body: '{"q": 1}'})
      .then(response => {
        store.setUsers(response)
      })
      .catch(err => {
        store.setUsers([])
        console.log(err)
      });
}
export const refreshServers = function() {
  API.post(store.state.api, "/get", {body: '{"q": 2}'})
      .then(response => {
        let servers = {};
        for (let server of response) {
          servers[server.id] = server;
        }
        store.setServers(servers)
      })
      .catch(err => {
        store.setServers([])
        console.log(err)
      });
}
export const refreshClients = function () {
  API.post(store.state.api, "/get", {body: '{"q": 3}'})
      .then(respClients => {
        API.post(store.state.api, "/get", {body: '{"q": 8}'})
            .then(respKeycloaks => {
              let clients = {}
              for (let client of respClients) {
                clients[client.id] = client
                client.keycloaks = {}
                if (client.starredTab === undefined) {
                  client.starredTab = null
                }
              }
              for (let keycloak of respKeycloaks) {
                let client = clients[keycloak.clientId]
                if (client !== undefined) {
                  client.keycloaks[keycloak.id] = keycloak
                }
              }
              store.setClients(clients)
            })
            .catch(err => {
              store.setClients({})
              console.log(err)
            });
      })
      .catch(err => {
        store.setClients([])
        console.log(err)
      });
}

export const generateConfigurationLink = function(apiKey) {
  try {
    const serverUrl = store.state.servers[apiKey.serverId].url
    const configuration = {
      server: serverUrl,
      apikey: apiKey.apiKey,
    }
    return 'https://configuration.olvid.io/#' + base64encode(JSON.stringify(configuration))
  } catch (e) {
    console.log(e)
    return ''
  }
}

export const formatDate = function (date) {
  if (typeof date === "number") {
    date = new Date(date);
  } else if (!(date instanceof Date)) {
    return null;
  }
  return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0') + ' - ' + date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear();
}

export const formatShortDate = function (date) {
  if (typeof date === "number") {
    date = new Date(date);
  } else if (!(date instanceof Date)) {
    return null;
  }
  return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear();
}

new Vue({
  render: h => h(AuthenticationHeader)
}).$mount('#header')

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
