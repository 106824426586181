<template>
  <b-container fluid id="main">
    <b-form inline style="float:right">
      <b-input placeholder="Search API key" v-model="pendingFilter" @keydown.enter.prevent.native="openSearch" style="margin: 8px 5px 0 0;"/>
      <b-btn variant="primary" :disabled="pendingFilter.length === 0" @click="openSearch" style="margin: 8px 0 0 0;">Search</b-btn>
    </b-form>
    <h1>Api key search <span v-if="localStore.apiKeys !== null" id="keyCount">({{ localStore.selectedKeys.length > 0 ? localStore.selectedKeys.length + '/':'' }}{{ Object.keys(localStore.apiKeys).length }} key{{ Object.keys(localStore.apiKeys).length > 1 ? 's':'' }})</span></h1>

    <b-row>
      <b-col>
        <b-table striped hover no-sort-reset sort-by="expirationTimestamp" :filter="apiKeyFilter" :filter-included-fields="['apiKey']" :sort-null-last="true" :busy="localStore.apiKeys === null" :items="localStore.apiKeys === null ? [] : Object.values(localStore.apiKeys)" :fields="fields" :sort-compare="sortActiveByRevocationDate" primary-key="apiKey" @row-clicked="rowClicked">
          <template v-slot:head(checkbox)="">
            <b-form-checkbox id="cbx-all" @change="checked => checkAll(checked)"/>
          </template>

          <template v-slot:cell(checkbox)="apiKey">
            <b-form-checkbox class="selectionCheckbox" :id="'cbx-' + apiKey.item.apiKey" v-model="localStore.selectedKeys" :value="apiKey.item.apiKey"/>
          </template>

          <template v-slot:head(apiKey)="">
            <b-form inline>
              <label>Api Key</label>
              <b-form-input size="sm" placeholder="Filter" v-model="apiKeyFilter" class="filter"/>
            </b-form>
          </template>

          <template v-slot:cell(call)="apiKey">
            <b-icon-check v-if="apiKey.item.permissions.includes('call')" variant="success" scale="2"/>
            <b-icon-x v-else variant="danger" scale="2" />
          </template>

          <template v-slot:cell(multi_device)="apiKey">
            <b-icon-check v-if="apiKey.item.permissions.includes('multi_device')" variant="success" scale="2"/>
            <b-icon-x v-else variant="danger" scale="2" />
          </template>

          <template v-slot:cell(expirationTimestamp)="apiKey">
            <span v-if="!apiKey.item.expirationTimestamp">-</span>
            <span v-else :style="apiKey.item.expirationTimestamp < new Date().getTime() ? 'color: #dc3545': '' ">{{ formatShortDate(new Date(apiKey.item.expirationTimestamp)) }}</span>
          </template>

          <template v-slot:cell(active)="apiKey">
            <b-icon-check v-if="apiKey.item.active" variant="secondary" scale="2"/>
            <div v-else>
              <b-icon-x variant="secondary" scale="2" />
              <div v-if="apiKey.item.revocationTimestamp" class="revoked">
                Revoked:<br/> {{ formatDate(new Date(apiKey.item.revocationTimestamp)) }}
              </div>
            </div>
          </template>

          <template v-slot:cell(assigned)="apiKey">
            <b-icon-check v-if="apiKey.item.assigned" variant="success" scale="2"/>
            <b-icon-x v-else variant="secondary" scale="2" />
          </template>

          <template v-slot:cell(link)="apiKey">
            <b-link :href="generateConfigurationLink(apiKey.item)" target="_blank">link</b-link>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-navbar v-if="localStore.selectedKeys.length > 0 && client !== null" fixed="bottom" :sticky=true id="actionMode">
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <div class="buttons">
            <edit-api-keys-button :api-keys="localStore.selectedKeys.map(key => {return localStore.apiKeys[key]})" :client="client" :callback="refreshKeys"/>
            <revoke-api-keys-button :api-keys="localStore.selectedKeys" :client="client" :callback="refreshKeys"/>
            <delete-api-keys-button :api-keys="localStore.selectedKeys" :client="client" :callback="refreshKeys"/>
          </div>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
  </b-container>
</template>

<script>
import {API} from "aws-amplify";
import {formatDate, formatShortDate, generateConfigurationLink, store} from "@/main"
import EditApiKeysButton from "@/components/buttons/EditApiKeysButton.vue";
import RevokeApiKeysButton from "@/components/buttons/RevokeApiKeysButton.vue";
import DeleteApiKeysButton from "@/components/buttons/DeleteApiKeysButton.vue";

const localStore = {
    apiKeys: null,
    selectedKeys: [],
}

const searchApiKeys = function (filter) {
    localStore.apiKeys = null
    localStore.selectedKeys = []
    API.post(store.state.api, "/get", {body: JSON.stringify({
            q: 6,
            filter: filter,
        }),
    })
        .then(response => {
            let keys = {}
            for (let key of response) {
                keys[key.apiKey] = key
            }
            localStore.apiKeys = keys
        })
        .catch(() => {
            localStore.apiKeys = []
        });
}

export default {
  name: "ApiKeySearch",
  components: {DeleteApiKeysButton, RevokeApiKeysButton, EditApiKeysButton},
  props: {
    filter: String,
  },
  data() {
    return {
      localStore: localStore,
      storeState: store.state,
      apiKeyFilter: "",
      pendingFilter: "",
      fields: [
        {
          key: 'checkbox',
          label: '',
          thStyle: {width: '40px'},
        },
        {
          key: 'clientId',
          label: 'Client',
          sortable: true,
          formatter: clientId => {
            const client = store.state.clients[clientId]
            return client === undefined ? '???' : client.name
          }
        },
        {
          key: 'apiKey',
          label: 'API key',
          sortable: true,
        },
        {
          key: 'assigned',
          label: 'Assigned',
          sortable: true,
        },
        {
          key: 'call',
          label: 'Call',
          sortable: true,
        },
        {
          key: 'multi_device',
          label: 'Multi-device',
          sortable: true,
        },
        {
          key: 'expirationTimestamp',
          label: 'Expiration date',
          sortable: true,
        },
        {
          key: 'active',
          label: 'Active',
          sortable: true,
        },
        {
          key: 'creationTimestamp',
          label: 'Creation date',
          sortable: true,
          formatter: timestamp => {
            return formatDate(new Date(timestamp))
          }
        },
        {
          key: 'link',
          label: 'Configuration Link',
          sortable: false,
        }
      ],
    }
  },
  computed: {
    client() {
      // also check the client
      if (localStore.selectedKeys.length !== 0) {
        let clientId = localStore.apiKeys[localStore.selectedKeys[0]].clientId
        localStore.selectedKeys.every(apiKey => {
          if (localStore.apiKeys[apiKey].clientId !== clientId) {
            clientId = null
            return false
          }
          return true
        });
        if (clientId !== null) {
          return this.storeState.clients[clientId]
        }
      }
      return null
    },
    prod() {
      return store.state.prod;
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    formatShortDate(date) {
      return formatShortDate(date)
    },
    generateConfigurationLink(apiKey) {
      return generateConfigurationLink(apiKey)
    },
    sortActiveByRevocationDate: function (aRow, bRow, key) {
      if (key === 'active') {
        if (aRow.active !== bRow.active) {
          return aRow.active ? 1 : -1;
        }
        let aExp = aRow.revocationTimestamp
        let bExp = bRow.revocationTimestamp
        if (aExp === undefined) {
          return bExp === undefined ? 0 : 1
        } else if (bExp === undefined) {
          return -1
        } else {
          return aExp > bExp ? -1 : 1
        }
      }
      return null
    },
    openSearch() {
      if (this.pendingFilter.length === 0 || this.pendingFilter === this.filter) {
        return
      }
      this.$router.push('/search/' + this.pendingFilter)
    },
    refreshKeys() {
      searchApiKeys(this.filter)
    },
    checkAll(checked) {
      if (checked && localStore.apiKeys !== null) {
        localStore.selectedKeys = Object.keys(localStore.apiKeys)
      } else {
        localStore.selectedKeys = [];
      }
    },
    rowClicked(apiKey) {
      if (localStore.selectedKeys.includes(apiKey.apiKey)) {
        localStore.selectedKeys = localStore.selectedKeys.filter(value => value !== apiKey.apiKey);
      } else {
        localStore.selectedKeys.push(apiKey.apiKey);
      }
    },
  },
  mounted() {
    this.pendingFilter = this.filter
    searchApiKeys(this.filter)
  },
  watch: {
    filter() {
      this.refreshKeys()
    },
    prod() {
      this.refreshKeys()
    }
  }
}
</script>

<style scoped>
#main {
    padding-top: 15px;
}
.buttons {
    float: right;
}
.buttons > * {
    margin-left: 10px;
}
#actionMode {
    background: rgba(255,255,255,.8);
    border-top: 1px solid #ccc;
}
#keyCount {
    font-size: 75%;
    font-weight: lighter;
    margin-left: 20px;
}
.filter {
    margin: -8px 0 -8px 20px;
}
.revoked {
    display: inline-block;
    font-size: 60%;
    color: red;
    vertical-align: middle;
    margin-bottom: 4px;
    white-space: nowrap;
    margin-left: 10px;
    line-height: 1;
}
</style>