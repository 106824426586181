<template>
  <span class="block">
    <input
        v-if="editing"
        v-model="editedText"
        @keydown.enter="saveValue"
        @keydown.esc="discardValue"
        @blur="discardValue"
        ref="input"
        :size="Math.min(Math.max(editedText.length, 5), 40)"/>
    <span v-else class="editable" @click="startEditing">{{ text }}</span>
    <span class="mask" v-if="processing">
      <b-spinner class="centered-spinner" variant="primary"/>
    </span>
  </span>
</template>

<script>
export default {
  name: "InlineInput",
  props: {
    text: String,
    save: Function,
  },
  data: function() {
    return {
      editing: false,
      editedText: null,
      processing: false,
    }
  },
  methods: {
    saveValue() {
      this.processing = true
      this.save(this.editedText, () => {
        this.processing = false
        this.editing = false
      })
    },
    discardValue() {
      this.editedText = null
      this.editing = false
    },
    startEditing() {
      this.editedText = this.text
      this.editing = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
  }
}
</script>

<style scoped>
.block {
  display: inline-block;
  position: relative
}
input {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  line-height: normal;
  padding: 0;
  margin: 0;
  display: inline;
}
.editable {
  cursor: pointer;
}
.mask {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255,255,255,0.75);
}
.centered-spinner {
  font-size: 1rem;
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(40% - 15px);
}
</style>