<template>
  <span>
    <b-button variant="outline-info" @click="syncDb" style="width: 130px">
      <b-spinner small v-if="processing"></b-spinner>
      <span v-show="!processing"><b-icon-arrow-clockwise style="margin-right: 5px"/> Sync DB</span>
    </b-button>
  </span>
</template>

<script>
import {API} from "aws-amplify";
import {store} from "@/main";

export default {
  name: "SyncButton",
  props: {
    client: Object,
    callback: Function,
  },
  data: function() {
    return {
      processing: false,
    }
  },
  methods: {
    syncDb() {
      this.processing = true;
      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 100,
        }),
      })
          .then(() => {
            this.processing = false;
            this.callback()
          })
          .catch(() => {
            this.processing = false;
          });
    }
  },
}
</script>

<style scoped>

</style>