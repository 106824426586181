<template>
  <span>
    <b-button variant="primary" @click="showModal">Edit {{ apiKeys.length }} key{{ apiKeys.length > 1 ? 's':''}}</b-button>

    <b-modal ref="modal" :scrollable=true id="modal-edit-apikeys" title="Edit API keys" centered>
      <b-container fluid>
        <b-row>
          <b-col sm="12">
          Choose how you want to edit the {{ apiKeys.length> 1 ? apiKeys.length+' ': '' }}selected API key{{ apiKeys.length > 1 ? 's':''}} for <strong>{{ client.name }}</strong>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
          <b-form-group label="Permissions:">
            <b-form-checkbox
                id="permission-checkbox-call"
                v-model="canCall"
                :indeterminate="permissionsIndeterminateCall"
                @input="modifiedPermissions = true; permissionsIndeterminateMultiDevice = false">Call</b-form-checkbox>
            <b-form-checkbox
                id="permission-checkbox-multi-device"
                v-model="canMultiDevice"
                :indeterminate="permissionsIndeterminateMultiDevice"
                @input="modifiedPermissions = true; permissionsIndeterminateCall = false">Multi-device</b-form-checkbox>
        </b-form-group>
        <b-form-group
            id="expiration-input-group"
            label="Expiration:"
            label-for="expiration-input">
          <b-form-input id="expiration-input" type="date" v-model="expirationDate" @input="modifiedExpiration = true"/>
        </b-form-group>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="editApiKeys" :disabled="!(modifiedPermissions || modifiedExpiration)">
          <b-spinner small v-show="processing"></b-spinner>
          <span v-show="!processing">Update</span>
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import {API} from "aws-amplify";
import {store} from "@/main";

export default {
    name: "EditApiKeysButton",
    props: {
        apiKeys: Array,
        client: Object,
        callback: Function,
    },
    data() {
        return {
            processing: false,
            err: ' ',
            canCall: true,
            canMultiDevice: true,
            permissionsIndeterminateCall: false,
            permissionsIndeterminateMultiDevice: false,
            expirationDate: null,
            modifiedPermissions: false,
            modifiedExpiration: false,
        }
    },
    methods: {
        showModal() {
            this.initModal();
            this.$refs['modal'].show();
        },
        initModal() {
            if (this.apiKeys.length > 0) {
                this.canCall = this.apiKeys[0].permissions.includes('call');
                this.canMultiDevice = this.apiKeys[0].permissions.includes('multi_device');
                this.permissionsIndeterminateCall = false;
                this.permissionsIndeterminateMultiDevice = false;
                this.apiKeys.forEach(apiKey => {
                    if (apiKey.permissions.includes('call') !== this.canCall) {
                        this.permissionsIndeterminateCall = true;
                    }
                    if (apiKey.permissions.includes('multi_device') !== this.canMultiDevice) {
                        this.permissionsIndeterminateMultiDevice = true;
                    }
                });

                let expirationTimestamp = null;
                this.apiKeys.forEach(apiKey => {
                    if (apiKey.expirationTimestamp) {
                        if (expirationTimestamp !== null) {
                            expirationTimestamp = Math.max(apiKey.expirationTimestamp, expirationTimestamp);
                        } else {
                            expirationTimestamp = apiKey.expirationTimestamp;
                        }
                    }
                });
                if (expirationTimestamp) {
                    const date = new Date(expirationTimestamp);
                    this.expirationDate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
                } else {
                    this.expirationDate = null;
                }
            }
        },
        editApiKeys() {
            this.processing = true;
            this.err = ' ';
            const query = {
                q: 50,
                clientId: this.client.id,
                apiKeys: this.apiKeys.map(apiKey => apiKey.apiKey),
            };
            if (this.modifiedPermissions) {
                const permissions = [];
                if (this.canCall) {
                    permissions.push('call');
                }
                if (this.canMultiDevice) {
                    permissions.push('multi_device');
                }
                query.permissions = permissions;
                query.updatePermissions = true;
            }
            if (this.modifiedExpiration) {
                if (this.expirationDate !== null) {
                    const components = this.expirationDate.split("-")
                    const date = new Date(components[0], components[1]-1, components[2])
                    query.expirationTimestamp = date.getTime()
                }
                query.updateExpiration = true;
            }
            API.post(store.state.api, "/put", {
                body: JSON.stringify(query),
            })
                .then(() => {
                    this.processing = false;
                    this.$refs['modal'].hide();
                    this.callback();
                })
                .catch(() => {
                    this.processing = false;
                    this.err = 'Error deleting API key' + (this.apiKeys.length > 1 ? 's':'');
                });
        },
    }
}
</script>

<style scoped>
ul {
  margin: 20px 0;
}
li {
  font-family: monospace;
  font-size: 75%;
}
</style>