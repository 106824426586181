<template>
  <b-container fluid id="main">
    <div class="buttons">
      <div :class='"counter" + ((localStore.assigned === true) ? " green" : (localStore.assigned === false) ? " red" : "")' @click="selectAssigned">
        <div class="count">{{ assignedKeys }}</div>
        <div class="countLabel">Assigned</div>
      </div>
      <div :class='"counter" + ((localStore.active === true) ? " green" : (localStore.active === false) ? " red" : "")' @click="selectActive">
        <div class="count">{{ activeKeys }}</div>
        <div class="countLabel">Active</div>
      </div>
      <download-api-keys-csv-button :client="client" :api-keys="localStore.selectedKeys.length > 0 ? localStore.selectedKeys.map(s => localStore.apiKeys[s]) : (localStore.apiKeys === null ? []:Object.values(localStore.apiKeys))" :disabled="localStore.apiKeys === null"/>
    </div>
    <h1>API keys <star-tab :client="client" :tab-id="-1" /><span v-if="localStore.apiKeys !== null" id="keyCount">({{ localStore.selectedKeys.length > 0 ? localStore.selectedKeys.length + '/':'' }}{{ Object.keys(localStore.apiKeys).length }} key{{ Object.keys(localStore.apiKeys).length > 1 ? 's':'' }})</span></h1>
    <p v-if="storeState.servers !== null">{{ storeState.servers[client.defaultServerId] ? storeState.servers[client.defaultServerId].url : '' }} </p>



    <b-row>
      <b-col>
        <b-table striped hover no-sort-reset
                 :sort-by="loadSortBy()"
                 :sort-desc="loadSortDesc()"
                 @sort-changed="saveSort"
                 :sort-null-last="!localStore.sortIsDesc"
                 :filter="apiKeyFilter"
                 :filter-included-fields="['apiKey']"
                 :busy="localStore.apiKeys === null"
                 :items="localStore.apiKeys === null ? [] : Object.values(localStore.apiKeys)"
                 :fields="fields"
                 :sort-compare="sortActiveByRevocationDate"
                 primary-key="apiKey"
                 @row-clicked="rowClicked">
          <template v-slot:head(checkbox)="">
            <b-form-checkbox id="cbx-all" @change="checked => checkAll(checked)"/>
          </template>

          <template v-slot:cell(checkbox)="apiKey">
            <b-form-checkbox class="selectionCheckbox" :id="'cbx-' + apiKey.item.apiKey" v-model="localStore.selectedKeys" :value="apiKey.item.apiKey" @change="() => {localStore.active = null; localStore.assigned = null}"/>
          </template>

          <template v-slot:head(apiKey)="">
            <b-form inline>
              <label>Api Key</label>
              <b-form-input size="sm" placeholder="Filter" v-model="apiKeyFilter" class="filter"/>
            </b-form>
          </template>

          <template v-slot:cell(call)="apiKey">
            <b-icon-check v-if="apiKey.item.permissions.includes('call')" variant="success" scale="2"/>
            <b-icon-x v-else variant="danger" scale="2" />
          </template>

          <template v-slot:cell(multi_device)="apiKey">
            <b-icon-check v-if="apiKey.item.permissions.includes('multi_device')" variant="success" scale="2"/>
            <b-icon-x v-else variant="danger" scale="2" />
          </template>

          <template v-slot:cell(expirationTimestamp)="apiKey">
            <span v-if="!apiKey.item.expirationTimestamp">-</span>
            <span v-else :style="apiKey.item.expirationTimestamp < new Date().getTime() ? 'color: #dc3545': '' ">{{ formatShortDate(new Date(apiKey.item.expirationTimestamp)) }}</span>
          </template>

          <template v-slot:cell(active)="apiKey">
            <b-icon-check v-if="apiKey.item.active" variant="secondary" scale="2"/>
            <div v-else>
              <b-icon-x variant="secondary" scale="2" />
              <div v-if="apiKey.item.revocationTimestamp" class="revoked">
                Revoked:<br/> {{ formatDate(new Date(apiKey.item.revocationTimestamp)) }}
              </div>
            </div>
          </template>

          <template v-slot:cell(assigned)="apiKey">
            <b-icon-check v-if="apiKey.item.assigned" variant="success" scale="2"/>
            <b-icon-x v-else variant="secondary" scale="2" />
          </template>

          <template v-slot:cell(link)="apiKey">
            <b-link :href="generateConfigurationLink(apiKey.item)" target="_blank">link</b-link>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-navbar v-if="localStore.selectedKeys.length > 0" fixed="bottom" :sticky=true id="actionMode">
      <b-navbar-nav class="ml-auto">
      <b-nav-form>
        <div class="buttons">
          <edit-api-keys-button :api-keys="localStore.selectedKeys.map(key => {return localStore.apiKeys[key]})" :client="client" :callback="refreshKeys"/>
          <revoke-api-keys-button :api-keys="localStore.selectedKeys" :client="client" :callback="refreshKeys"/>
          <delete-api-keys-button :api-keys="localStore.selectedKeys" :client="client" :callback="refreshKeys"/>
        </div>
      </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
  </b-container>
</template>

<script>
import {API} from "aws-amplify";
import {formatDate, formatShortDate, generateConfigurationLink, store} from "@/main"
import DeleteApiKeysButton from "@/components/buttons/DeleteApiKeysButton.vue"
import DownloadApiKeysCsvButton from "@/components/buttons/DownloadApiKeysCsvButton.vue"
import EditApiKeysButton from "@/components/buttons/EditApiKeysButton.vue"
import RevokeApiKeysButton from "@/components/buttons/RevokeApiKeysButton.vue"
import StarTab from "@/components/atoms/StarTab.vue";

const localStore = {
  apiKeys: null,
  selectedKeys: [],
  assigned: null,
  active: null,
  sortIsDesc: false,
}

const refreshApiKeys = function (clientId) {
  localStore.apiKeys = null
  localStore.selectedKeys = []
  localStore.assigned = null
  localStore.active = null
  API.post(store.state.api, "/get", {body: JSON.stringify({
      q: 4,
      clientId: clientId,
    }),
  })
      .then(response => {
        let keys = {}
        let activeCount = 0
        let assignedCount = 0
        for (let key of response) {
          keys[key.apiKey] = key
          if (key.active) {
            activeCount++
            if (key.assigned) {
              assignedCount++
            }
          }
        }
        localStore.apiKeys = keys
        store.state.clients[clientId].apiKeyCount = activeCount
        store.state.clients[clientId].apiKeyAssignedCount = assignedCount
      })
      .catch(() => {
        localStore.apiKeys = []
      });
}

export default {
  name: "ClientApiKeys",
  props: {
    client: Object
  },
  components: {StarTab, DownloadApiKeysCsvButton, DeleteApiKeysButton, EditApiKeysButton, RevokeApiKeysButton},
  data() {
    return {
      localStore: localStore,
      storeState: store.state,
      apiKeyFilter: "",
        sortActiveByRevocationDate: function(aRow, bRow, key) {
          if (key === 'active') {
            if (aRow.active !== bRow.active) {
              return aRow.active ? 1 : -1;
            }
            let aExp = aRow.revocationTimestamp
            let bExp = bRow.revocationTimestamp
            if (aExp === undefined) {
              return bExp === undefined ? 0 : 1
            } else if (bExp === undefined) {
              return -1
            } else {
              return aExp > bExp ? -1 : 1
            }
          }
          return null
        },
      fields: [
        {
          key: 'checkbox',
          label: '',
          thStyle: {width: '40px'},
        },
        {
          key: 'apiKey',
          label: 'API key',
          sortable: true,
        },
        {
          key: 'assigned',
          label: 'Assigned',
          sortable: true,
        },
        {
          key: 'call',
          label: 'Call',
          sortable: true,
        },
        {
          key: 'multi_device',
          label: 'Multi-device',
          sortable: true,
        },
        {
          key: 'lastRegTimestamp',
          label: 'Last online',
          sortable: true,
          formatter: timestamp => {
            return formatShortDate(timestamp)
          }
        },
        {
          key: 'expirationTimestamp',
          label: 'Expiration date',
          sortable: true,
        },
        {
          key: 'active',
          label: 'Active',
          sortable: true,
        },
        {
          key: 'creationTimestamp',
          label: 'Creation date',
          sortable: true,
          formatter: timestamp => {
            return formatDate(timestamp)
          }
        },
        {
          key: 'link',
          label: '',
          sortable: false,
        }
      ],
    }
  },
  methods: {
    formatDate(date) {return formatDate(date)},
    formatShortDate(date) {return formatShortDate(date)},
    refreshKeys() {refreshApiKeys(this.client.id)},
    generateConfigurationLink(apiKey){ return generateConfigurationLink(apiKey) },
    checkAll(checked) {
      if (checked && localStore.apiKeys !== null) {
        localStore.selectedKeys = Object.keys(localStore.apiKeys)
      } else {
        localStore.selectedKeys = [];
      }
      localStore.assigned = null
      localStore.active = null
    },
    rowClicked(apiKey) {
      localStore.assigned = null
      localStore.active = null
      if (localStore.selectedKeys.includes(apiKey.apiKey)) {
        localStore.selectedKeys = localStore.selectedKeys.filter(value => value !== apiKey.apiKey)
      } else {
        localStore.selectedKeys.push(apiKey.apiKey)
      }
    },
    selectAssigned() {
      if (localStore.assigned === null) {
        localStore.assigned = true
      } else if (localStore.assigned === true) {
        localStore.assigned = false
      } else {
        localStore.assigned = null
      }
      this.filterActiveAssigned()
    },
    selectActive() {
      if (localStore.active === null) {
        localStore.active = true
      } else if (localStore.active === true) {
        localStore.active = false
      } else {
        localStore.active = null
      }
      this.filterActiveAssigned()
    },
    filterActiveAssigned() {
      localStore.selectedKeys = []
      if (localStore.apiKeys !== null && (localStore.active !== null || localStore.assigned !== null)) {
        Object.values(localStore.apiKeys).forEach(apiKey => {
          if ((localStore.active === null || (localStore.active && apiKey.active) || (!localStore.active && !apiKey.active))
              && (localStore.assigned === null || (localStore.assigned && apiKey.assigned) || (!localStore.assigned && !apiKey.assigned))) {
            localStore.selectedKeys.push(apiKey.apiKey)
          }
        })
      }
    },
    saveSort: function (ctx) {
      this.localStore.sortIsDesc = ctx.sortDesc
      localStorage.setItem("keysSort", ctx.sortBy + ',' + ctx.sortDesc)
    },
    loadSortBy: function() {
      const sort = localStorage.getItem("keysSort")
      if (sort && sort.includes(',')) {
        return sort.split(',')[0]
      }
      return 'expirationTimestamp'
    },
    loadSortDesc: function() {
      const sort = localStorage.getItem("keysSort")
      if (sort && sort.includes(',')) {
        this.localStore.sortIsDesc = sort.split(',')[1] === 'true'
      } else {
        this.localStore.sortIsDesc = false
      }
      return this.localStore.sortIsDesc
    },
  },
  watch: {
    client() {
      refreshApiKeys(this.client.id)
    },
  },
  computed: {
    assignedKeys() {
      if (localStore.apiKeys === null) {
        return "-"
      }
      return Object.values(localStore.apiKeys).reduce((acc, apiKey) => acc + (apiKey.assigned === true), 0)
    },
    activeKeys() {
      if (localStore.apiKeys === null) {
        return "-"
      }
      return Object.values(localStore.apiKeys).reduce((acc, apiKey) => acc + (apiKey.active === true), 0)
    }
  },
  mounted() {
    refreshApiKeys(this.client.id)
  }
}
</script>

<style scoped>
#main {
  padding-top: 15px;
}
.buttons {
  float: right;
  vertical-align: top;
}
.buttons > * {
  margin-left: 10px;
}
#actionMode {
  background: rgba(255,255,255,.8);
  border-top: 1px solid #ccc;
}
#keyCount {
  font-size: 75%;
  font-weight: lighter;
  margin-left: 20px;
}
.filter {
  margin: -8px 0 -8px 20px;
}
.revoked {
  display: inline-block;
  font-size: 60%;
  color: red;
  vertical-align: middle;
  margin-bottom: 4px;
  white-space: nowrap;
  margin-left: 10px;
  line-height: 1;
}
.counter {
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 5px;
  padding-bottom: 4px;
  min-width: 90px;
}
.counter.green {
  background: #595;
  color: #fff;
}
.counter.red {
  background: #dc3545;
  color: #fff;
}
.count {
  font-size: 200%;
  text-align: center;
}
.countLabel {
  font-size: 80%;
  text-align: center;
}

</style>
