<template>
  <span>
    <b-button variant="warning" v-b-modal.modal-revoke-apikeys>Revoke {{ apiKeys.length }} key{{ apiKeys.length > 1 ? 's':''}}</b-button>

  <b-modal ref="modal" :scrollable=true id="modal-revoke-apikeys" title="Revoke API keys" centered>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          Really revoke the {{ apiKeys.length> 1 ? apiKeys.length+' ': '' }}following API key{{ apiKeys.length > 1 ? 's':''}} for <strong>{{ client.name }}</strong>?
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="error">{{ err }}</div>
          <ul>
            <li v-for="apiKey in apiKeys" :key="apiKey">{{ apiKey }}</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="revokeApiKeys">
        <b-spinner small v-show="processing"></b-spinner>
        <span v-show="!processing">Revoke</span>
      </b-button>
    </template>
  </b-modal>
  </span>
</template>

<script>
import {API} from "aws-amplify";
import {store} from "@/main";

export default {
  name: "RevokeApiKeysButton",
  props: {
    apiKeys: Array,
    client: Object,
    callback: Function,
  },
  data() {
    return {
      processing: false,
      err: ' ',
    }
  },
  methods: {
    revokeApiKeys() {
      this.processing = true;
      this.err = ' ';
      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 51,
          apiKeys: this.apiKeys,
          clientId: this.client.id,
        }),
      })
          .then(() => {
            this.processing = false;
            this.$refs['modal'].hide();
            this.callback();
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error revoking API key' + (this.apiKeys.length > 1 ? 's':'');
          });
    },
  }
}
</script>

<style scoped>
ul {
  margin: 20px 0;
}
li {
  font-family: monospace;
  font-size: 75%;
}

</style>