<template>
  <b-container fluid id="main">
    <div class="top-bar">
      <b-form inline>
        <b-input placeholder="Search API key" @input="setFilter($event)" @keydown.enter.prevent.native="openSearch" style="margin: -8px 5px -8px 0;"/>
        <b-btn variant="primary" :disabled="filter === null" @click="openSearch">Search</b-btn>
        <span class="spacer"/>
        <add-server-button/>
      </b-form>
    </div>
    <b-row>
      <b-col>
        <b-table striped hover :busy="storeState.servers === null" :items="storeState.servers === null?[]:Object.values(storeState.servers)" :fields="fields">
          <template v-slot:cell(id)="server">
            <delete-server-button v-bind:server="server.item"/>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(apiKeyLink)="server">
            <b-form inline>
              <b-input placeholder="API key" @input="apiKeyInput(server.item.id, $event)" @keydown.enter.prevent style="margin: -8px 20px -8px 0;"/>
              <b-link :id="'link-' + server.item.id" href="https://configuration.olvid.io/#" target="_blank">link</b-link>
            </b-form>
          </template>
          <template v-slot:cell(freeKeyLink)="server">
            <b-form inline>
              <b-input type="date" :value="inOneMonth()" :id="'date-' + server.item.id" @keydown.enter.prevent style="margin: -8px 20px -8px 0; width: 150px"/>
              <b-link @click="freeKeyClicked(server.item.id, $event.target)" href="#" target="_blank">free trial <b-spinner small/></b-link>
            </b-form>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <a id="free-trial-hidden-link" target="_blank"/>
  </b-container>
</template>

<script>
import {generateConfigurationLink, store} from "@/main";

import AddServerButton from "@/components/buttons/AddServerButton.vue";
import DeleteServerButton from "@/components/buttons/DeleteServerButton.vue";
import {API} from "aws-amplify";



export default {
  name: "ServerList",
  components: {AddServerButton, DeleteServerButton},
  data() {
    return {
      storeState: store.state,
      filter: null,
      fields: [
        {
          key: 'id',
          label: '',
          thStyle: {width: '49px'},
        },
        {
          key: 'url',
          label: 'URL',
          sortable: true,
        },
        {
          key: 'apiKeyLink',
          label: 'Link generator',
          sortable: false,
          thStyle: {width: '350px'},
        },
        {
          key: 'freeKeyLink',
          label: 'Free Trial generator',
          sortable: false,
          thStyle: {width: '350px'},
        }
      ],
    }
  },
  methods: {
    inOneMonth() {
      let date = new Date(new Date().getTime() + 31 * 86400000);
      return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
    },
    apiKeyInput(serverId, value) {
      document.getElementById('link-' + serverId).href = generateConfigurationLink({serverId: serverId, apiKey: value});
    },
    freeKeyClicked(serverId, target) {
      target.classList.add("loading");
      const components = document.getElementById('date-' + serverId).value.split("-")
      const date = new Date(components[0], components[1] - 1, components[2])
      API.post(store.state.api, "/get", {
        body: JSON.stringify({
          q: 5,
          timestamp: date.getTime(),
        }),
      })
          .then((response) => {
            target.classList.remove("loading");
            const a = document.getElementById('free-trial-hidden-link');
            const url = generateConfigurationLink({serverId: serverId, apiKey: response});
            a.href = url;
            a.click();
          })
          .catch(() => {
            target.classList.remove("loading");
          });
    },
    setFilter(filter) {
      this.filter = filter.length === 0 ? null : filter
    },
    openSearch() {
      this.$router.push('/search/' + this.filter)
    }
  },
    mounted() {
      this.storeState.apiKeyFilter = null
    }
}
</script>

<style scoped>
#main {
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-bar {
  margin-bottom: 15px;
}
.top-bar .spacer {
    flex-grow: 1;
}
a span.spinner-border {
  display: none;
  margin-left: 10px;
}
a.loading span.spinner-border {
  display: inline-block;
}
#free-trial-hidden-link {
  display: none;
}
a.disabled {
    pointer-events: none;
    opacity: .5;
}
</style>