<template>
  <span>
    <b-button variant="success" v-b-modal.modal-generate-keys><b-icon-plus-square-fill style="margin-right: 5px"/> Generate API keys</b-button>

    <b-modal ref="modal" id="modal-generate-keys" :title="'Generate API keys for ' + client.name" centered>
      <b-container fluid>
        <b-form-group
            id="number-input-group"
            label="Number of keys:"
            label-for="number-input">
            <b-form-input id="number-input" type="text" placeholder="-" v-model="numberOfKeys"/>
        </b-form-group>
        <b-form-group label="Permissions:">
          <b-form-checkbox-group
              id="permission-checkboxes-group"
              v-model="selectedPermissions"
              :options="permissionOptions"
              name="permission-checkboxes"/>
        </b-form-group>
        <b-form-group
            id="expiration-input-group"
            label="Expiration:"
            label-for="expiration-input">
            <b-form-input id="expiration-input" type="date" v-model="expirationDate"/>
        </b-form-group>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="generateApiKeys" :disabled="!(numberOfKeys > 0)">
          <b-spinner small v-if="processing"></b-spinner>
          <span v-show="!processing">Generate keys</span>
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import {API} from "aws-amplify";
import {store} from "@/main";

export default {
  name: "GenerateApiKeysButton",
  props: {
    client: Object,
    callback: Function,
  },
  data: function() {
    return {
      processing: false,
      err: ' ',
      numberOfKeys: null,
      expirationDate: null,
      selectedPermissions: ['call', 'multi_device'],
      permissionOptions: [{ text: 'Call', value: 'call', }, { text: 'Multi-device', value: 'multi_device', }],
    }
  },
  methods: {
    generateApiKeys() {
      this.processing = true;
      this.err = ' ';
      const query = {
        q: 3,
        clientId: this.client.id,
        serverId: this.client.defaultServerId,
        count: this.numberOfKeys,
        permissions: this.selectedPermissions,
      }
      if (this.expirationDate !== null) {
        const components = this.expirationDate.split("-")
        const date = new Date(components[0], components[1]-1, components[2])
        query.expirationTimestamp = date.getTime()
      }
      API.post(store.state.api, "/put", {
        body: JSON.stringify(query),
      })
          .then(() => { // TODO: use the response to avoid reloading the whole list of API keys
            this.processing = false;
            this.$refs['modal'].hide()
            this.callback()
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error generating API keys'
          });
    }
  },
}
</script>

<style scoped>

</style>