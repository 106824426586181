<template>
  <span>
    <b-button variant="success" v-b-modal.modal-add-server><b-icon-plus-square-fill style="margin-right: 5px"/> Add client</b-button>

    <b-modal ref="modal" id="modal-add-server" title="Add client" centered>
      <b-container fluid>
        <b-form-group
            id="name-input-group"
            label="Name:"
            label-for="name-input">
            <b-form-input id="name-input" type="text" placeholder="Client Name" v-model="clientName"></b-form-input>
        </b-form-group>
        <b-form-group
            id="server-input-group"
            label="Server:"
            label-for="server-input">
            <b-form-select id="server-input" type="text" v-model="defaultServer" :options="servers"></b-form-select>
        </b-form-group>
        <b-row>
          <b-col sm="12">
            <div class="error">{{ err }}</div>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="createClient" :disabled="!clientName || !defaultServer">
          <b-spinner small v-if="processing"></b-spinner>
          <span v-show="!processing">Create</span>
        </b-button>
      </template>
    </b-modal>


  </span>
</template>


<script>

import {API} from "aws-amplify";
import {refreshClients, store} from "@/main";

export default {
  name: "AddClientButton",
  data: function() {
    return {
      clientName: '',
      defaultServer: null,
      keycloakClient: false,
      processing: false,
      selectedPermissions: ['call', 'multi_device'],
      permissionOptions: [{ text: 'Call', value: 'call', }, { text: 'Multi-device', value: 'multi_device', }],
      err: ' ',
    }
  },
  computed: {
    servers() {
      return [{text: 'Select a server', value: null}].concat(store.state.servers===null?[]:Object.values(store.state.servers).map(function(server){ return {text: server.url, value: server}}));
    }
  },
  methods: {
    createClient() {
      this.processing = true;
      this.err = ' ';
      const query = {
        q: 2,
        serverId: this.defaultServer.id,
        name: this.clientName,
        keycloakClient: this.keycloakClient,
      }
      if (this.keycloakClient) {
        query.permissions = this.selectedPermissions
      }

      API.post(store.state.api, "/put", {
        body: JSON.stringify(query),
      })
          .then(() => {
            this.processing = false;
            this.$refs['modal'].hide()
            refreshClients()
          })
          .catch(() => {
            this.processing = false;
            this.err = 'Error creating client'
          });
    }
  }
}
</script>



<style scoped>
.error {
  display: block;
  color: red;
  margin-top: 10px;
  text-align: center;
}
</style>